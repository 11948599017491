const asynchronouslyImportScript = async (cssSelector: string, path: string) => {
  const elements = document.querySelectorAll<HTMLElement>(cssSelector);
  if (elements.length) {
    const script = await import(`@templates/${path}`);
    elements.forEach((element) => script.default.init(element));
  }
};

export default async () => {
  await asynchronouslyImportScript('.js-gridOverlay', '_components/gridOverlay/gridOverlay');
  await asynchronouslyImportScript('.js-navigation', '_components/navigation/navigation');
  await asynchronouslyImportScript('.js-scrollToTop', '_components/scrollToTop/scrollToTop');
  await asynchronouslyImportScript('.js-video', '_components/video/video');

  await asynchronouslyImportScript('.js-accordion', '_sections/accordion/accordion');
  await asynchronouslyImportScript('.js-backgroundColourSwitch', '_sections/backgroundColourSwitch/backgroundColourSwitch');
  await asynchronouslyImportScript('.js-introWrapper', '_sections/intro/intro');
  await asynchronouslyImportScript('.js-manifest', '_sections/manifest/manifest');
  await asynchronouslyImportScript('.js-quoteSlider', '_sections/quoteSlider/quoteSlider');
  await asynchronouslyImportScript('.js-slider', '_sections/slider/slider');
  await asynchronouslyImportScript('.js-team', '_sections/team/team');
};
